import React from 'react';
import { Provider } from 'react-redux';
import reduxThunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import Routes from './routes';
import rootReducers from './reducers';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

/**
 * Notes on redux use, just replace the first parameter of "createStore" to rootReducers above.
 * Don't forget to uncomment.
 */

// React GA Init
ReactGA.initialize(process.env.REACT_APP_GA_KEY);
ReactGA.pageview(window.location.pathname + window.location.search);
// React GTM Init
const tagManagerConfig = {
    gtmId: process.env.REACT_APP_GTM_KEY
};
TagManager.initialize(tagManagerConfig);

// Redux Init
const store = createStore(
    rootReducers,
    {},
    applyMiddleware(
        reduxThunk
    )
);

export default () => (
    <Provider store={store}>
        <Routes />
    </Provider>
);