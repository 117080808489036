import _ from 'lodash';
import moment from 'moment';

export default (
    state = [],
    action
) => {
    let data = [];

    switch (action.type)
    {
        case 'TALKSHOW_LOAD':
            // Convert object rows to array
            data = _.map(action.data, (row, key) => {
                const eventDate = row.eventDate.split('/');
                row.eventDate = `${eventDate[2]}-${eventDate[1]}-${eventDate[0]}`;
                
                row.day = eventDate[0];
                row.month = moment(row.eventDate).format('MMM');

                // Set key
                row.id = key;

                return row;
            });
            return data;
        default: return state;
    }
};