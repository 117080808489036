export default (
    state = [],
    action
) => {
    switch (action.type) {
        case 'LOAD_PROVINCES':
            let provinces = Object.assign({}, state);
            provinces = action.data;

            return provinces;
        default: return state;
    }
};