export default (
    state = {
        data: {
            name: '',
            email: '',
            phone: '',
            referralCode: '',
            candidate: {
                contest: '',
                name: '',
                email: '',
                phone: '',
                shortBio: '',
                photo: '',
                photoName: '',
                photoExt: '',
                facebookID: '',
                instagramID: ''
            }
        },
        referralCode: '',
        isCandidateExist: false,
        isCandidateLoading: false,
        isSubmit: false
    },
    action
) => {
    const data = Object.assign({}, state);
    switch (action.type) {
        case 'AGENT_DATA_CHECK_CANDIDATE_EMAIL_DONE':
            data.isCandidateExist = action.isExist;
            data.isCandidateLoading = false;
            return data;
        case 'AGENT_DATA_CHECK_CANDIDATE_EMAIL_LOADING':
            data.isCandidateLoading = true;
            return data;
        case 'AGENT_DATA_SUBMIT_SUCCESS':
            return {
                data: {
                    name: '',
                    email: '',
                    phone: '',
                    referralCode: '',
                    candidate: {
                        contest: '',
                        name: '',
                        email: '',
                        phone: '',
                        shortBio: '',
                        photo: '',
                        photoName: '',
                        photoExt: '',
                        facebookID: '',
                        instagramID: ''
                    }
                },
                isCandidateExist: false,
                isCandidateLoading: false,
                isSubmit: false,
                referralCode: action.referralCode
            };
        case 'AGENT_DATA_ON_SUBMIT':
            data.isSubmit = true;
            return data;
        default: return state;
    }
};