import mime from 'mime-types';

export default (
    state = {
        isLoading: false,
        data: {
            audition: {
                type: 'hitzjab-hunt',
                city: ''
            },
            fullname: '',
            band: '',
            email: '',
            phone: '',
            province: '',
            city: '',
            birthday: {
                date: '',
                month: '',
                year: ''
            },
            shortBio: '',
            school: '',
            photo: '',
            photoFileName: '',
            photoFileExt: '',
            facebookID: '',
            instagramID: '',
            websiteURL: '',
            referralCode: '',
            product: {
                name: '',
                status: false,
                concept: '',
                description: ''
            },
            isAgree: false
        },
        emailExist: {
            isLoaded: false,
            isExist: true
        }
    },
    action
) => {
    let data = {};
    let rawState = {};

    switch (action.type)
    {
        case 'HANDLE_USER_DATA_SET_EXIST':
            data = Object.assign({}, state.emailExist);
            data.isExist = action.isExist;
            data.isLoaded = false;
            return {...state, emailExist: data};
        case 'HANDLE_USER_DATA_SHOW_EMAIL_LOADER':
            data = Object.assign({}, state.emailExist);
            data.isLoaded = true;
            return {...state, emailExist: data};
        case 'HANDLE_USER_DATA_SET_PHOTO_NAME':
            data = Object.assign({}, state.data);
            data.photoFileName = action.name;
            data.photoFileExt = `.${mime.extension(action.mime)}`;
            return {...state, data};
        case 'HANDLE_USER_DATA_SAVE':
            rawState = Object.assign({}, state);
            rawState.isLoading = false;
            return rawState;
        case 'HANDLE_USER_DATA_UPLOAD_SINGLE':
            data = Object.assign({}, state.data);;
            data.photo = action.data;
            return {...state, data};
        case 'RESET_USER_DATA':
            rawState = Object.assign({}, state);
            rawState.data = {
                audition: {
                    type: 'hitzjab-hunt',
                    city: ''
                },
                fullname: '',
                band: '',
                email: '',
                phone: '',
                province: '',
                city: '',
                birthday: {
                    date: '',
                    month: '',
                    year: ''
                },
                shortBio: '',
                school: '',
                photo: '',
                photoFileName: '',
                photoFileExt: '',
                facebookID: '',
                instagramID: '',
                websiteURL: '',
                referralCode: '',
                product: {
                    name: '',
                    status: false,
                    concept: '',
                    description: ''
                },
                isAgree: false
            };
            rawState.isLoading = false;
            rawState.emailExist = {
                isLoaded: false,
                isExist: false
            };
            return rawState;
        case 'SHOW_USER_LOADING':
            rawState = Object.assign({}, state);
            rawState.isLoading = true;
            return rawState;
        case 'HANDLE_USER_DATA_INPUT':
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.data
                }
            };
        case 'HANDLE_USER_DATA_CHANGE_FORM':
            data = Object.assign({}, state.data);
            data.audition.type = action.name;
            return {...state, data};
        default: return state;
    }
};