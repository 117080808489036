export default (
    state = [],
    action
) => {
    switch (action.type) {
        case 'LOAD_CITIES':
            let cities = Object.assign({}, state);
            cities = action.data;
            return cities;
        default: return state;
    }
};